import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { auth } from "./firebase";
import { isLoggedIn, endSession } from "./session";
import { SubscriptionProvider, useSubscriptionContext } from './routes/SubscriptionContext';

// Import styles
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Loaders
const LineLoader = () => <LinearProgress />;

// Lazy imports
const Login = React.lazy(() => import("./routes/login"));
const Register = React.lazy(() => import("./routes/register"));
const Home = React.lazy(() => import("./routes/home"));
const Profile = React.lazy(() => import("./routes/profile"));
const Events = React.lazy(() => import("./routes/events"));
const Members = React.lazy(() => import("./routes/members"));
const Edit = React.lazy(() => import("./routes/edit"));
const Notifications = React.lazy(() => import("./routes/notifications"));
const Perfil = React.lazy(() => import("./routes/perfil"));
const SettingNotification = React.lazy(() => import("./routes/settingNotification"));
const Terms = React.lazy(() => import("./routes/terms"));
const Policy = React.lazy(() => import("./routes/privacyPolicy"));
const EditEvents = React.lazy(() => import("./routes/editEvents"));
const EditOffers = React.lazy(() => import("./routes/editOffers"));
const PublishOffer = React.lazy(() => import("./routes/publishOffer"));
const PublishEvents = React.lazy(() => import("./routes/publishEvents"));
const Atras = React.lazy(() => import("./routes/atras"));
const NotificationIcon = React.lazy(() => import("./routes/NotificationIcon"));
const User = React.lazy(() => import("./routes/user"));
const HeaderPerfil = React.lazy(() => import("./routes/HeaderPerfil"));
const Copy = React.lazy(() => import("./routes/copy"));
const BottomToolbar = React.lazy(() => import("./routes/BottomToolbar"));
const Chats = React.lazy(() => import("./routes/chats"));
const ChatBox = React.lazy(() => import("./routes/chatbox"));
const Subscription = React.lazy(() => import("./routes/subscription"));
const Search = React.lazy(() => import("./routes/search"));
const CheckOut = React.lazy(() => import("./routes/checkout"));
const QRScannerPay = React.lazy(() => import("./routes/QRScannerPay"));
const CobrarCQR = React.lazy(() => import("./routes/CobrarCQR"));
const QRScanner = React.lazy(() => import("./routes/QRScanner"));
const Autos = React.lazy(() => import("./routes/autos/cars"));
const BottomNav = React.lazy(() => import("./routes/autos/BottomNav"));
const MyCars = React.lazy(() => import("./routes/autos/myCars"));
const Vehiculos = React.lazy(() => import("./routes/autos/vehiculos"));
const PublishCar = React.lazy(() => import("./routes/autos/publishCar"));
const VerAuto = React.lazy(() => import("./routes/autos/ver"));
const Viajes = React.lazy(() => import("./routes/viajes/viajes"));
const Properties = React.lazy(() => import("./routes/viajes/myProperties"));
const Trips = React.lazy(() => import("./routes/viajes/trips"));
const PublishProperty = React.lazy(() => import("./routes/viajes/publishProperty"));
const VerLugar = React.lazy(() => import("./routes/viajes/ver"));
const HeaderViajes = React.lazy(() => import("./routes/viajes/Header3"));
const FloatingMenu = React.lazy(() => import("./routes/FloatingMenu"));

const FloatingMenuWrapper = ({ user }) => {
  const location = useLocation();
  const showFloatingMenu = user && location.pathname !== "/login" && location.pathname !== "/register";
  return showFloatingMenu ? <FloatingMenu /> : null;
};

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setIsAuthenticated(!!user);
      } catch (error) {
        console.error("Error checking auth:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (loading) return <LineLoader />;
  if (!isAuthenticated) return <Navigate to="/login" state={{ from: location }} replace />;
  return children;
};

const ProtectedPublish = ({ children }) => {
  const { subscription } = useSubscriptionContext();
  const isSubscriptionActive = subscription?.status === 'ACTIVE';
  const location = useLocation();
  const isLoggedIn = !!auth.currentUser;

  if (!isLoggedIn) return <Navigate to="/login" state={{ from: location }} replace />;
  if (!isSubscriptionActive) return <Navigate to="/subscription" state={{ from: location }} replace />;
  return children;
};

const AppRoutes = ({ user, handleLogout }) => (
  <Suspense fallback={<LineLoader />}>
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacyPolicy" element={<Policy />} />

        {/* Root route */}
        <Route path="/" element={user ? <Home handleLogout={handleLogout} /> : <Navigate to="/login" replace />} />

        {/* Protected basic routes */}
        <Route path="/home" element={<ProtectedRoute><Home handleLogout={handleLogout} /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/events" element={<ProtectedRoute><Events /></ProtectedRoute>} />
        <Route path="/members" element={<ProtectedRoute><Members /></ProtectedRoute>} />
        <Route path="/edit" element={<ProtectedRoute><Edit /></ProtectedRoute>} />
        <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
        <Route path="/settingNotification" element={<ProtectedRoute><SettingNotification /></ProtectedRoute>} />
        <Route path="/editEvents" element={<ProtectedRoute><EditEvents /></ProtectedRoute>} />
        <Route path="/editOffers" element={<ProtectedRoute><EditOffers /></ProtectedRoute>} />
        <Route path="/atras" element={<ProtectedRoute><Atras /></ProtectedRoute>} />
        <Route path="/notificationIcon" element={<ProtectedRoute><NotificationIcon /></ProtectedRoute>} />
        <Route path="/user/:userId" element={<ProtectedRoute><User /></ProtectedRoute>} />
        <Route path="/headerPerfil" element={<ProtectedRoute><HeaderPerfil /></ProtectedRoute>} />
        <Route path="/copy" element={<ProtectedRoute><Copy /></ProtectedRoute>} />
        <Route path="/bottomToolbar" element={<ProtectedRoute><BottomToolbar /></ProtectedRoute>} />
        <Route path="/chats" element={<ProtectedRoute><Chats /></ProtectedRoute>} />
        <Route path="/search" element={<ProtectedRoute><Search /></ProtectedRoute>} />
        <Route path="/subscription" element={<ProtectedRoute><Subscription /></ProtectedRoute>} />
        <Route path="/chatbox/:type/:itemId/:otherUserId" element={<ProtectedRoute><ChatBox /></ProtectedRoute>} />
        <Route path="/qrscanner" element={<ProtectedRoute><QRScanner /></ProtectedRoute>} />
        <Route path="/qrscannerpay" element={<ProtectedRoute><QRScannerPay /></ProtectedRoute>} />
        <Route path="/CobrarCQR" element={<ProtectedRoute><CobrarCQR /></ProtectedRoute>} />
        <Route path="/checkout/:type/:itemId" element={<ProtectedRoute><CheckOut /></ProtectedRoute>} />
        <Route path="/perfil/:uid" element={<ProtectedRoute><Perfil /></ProtectedRoute>} />

        {/* Protected auto routes */}
        <Route path="/cars" element={<ProtectedRoute><Autos /></ProtectedRoute>} />
        <Route path="/bottomNav" element={<ProtectedRoute><BottomNav /></ProtectedRoute>} />
        <Route path="/myCars" element={<ProtectedRoute><MyCars /></ProtectedRoute>} />
        <Route path="/vehiculos" element={<ProtectedRoute><Vehiculos /></ProtectedRoute>} />
        <Route path="/autos/ver/:carId" element={<ProtectedRoute><VerAuto /></ProtectedRoute>} />

        {/* Protected travel routes */}
        <Route path="/viajes" element={<ProtectedRoute><Viajes /></ProtectedRoute>} />
        <Route path="/properties" element={<ProtectedRoute><Properties /></ProtectedRoute>} />
        <Route path="/trips" element={<ProtectedRoute><Trips /></ProtectedRoute>} />
        <Route path="/viajes/ver/:placeId" element={<ProtectedRoute><VerLugar /></ProtectedRoute>} />
        <Route path="/headerViajes" element={<ProtectedRoute><HeaderViajes /></ProtectedRoute>} />

        {/* Subscription required routes */}
        <Route path="/publishOffer" element={<ProtectedRoute><ProtectedPublish><PublishOffer /></ProtectedPublish></ProtectedRoute>} />
        <Route path="/publishEvents" element={<ProtectedRoute><ProtectedPublish><PublishEvents /></ProtectedPublish></ProtectedRoute>} />
        <Route path="/publishProperty" element={<ProtectedRoute><ProtectedPublish><PublishProperty /></ProtectedPublish></ProtectedRoute>} />
        <Route path="/publishCar" element={<ProtectedRoute><ProtectedPublish><PublishCar /></ProtectedPublish></ProtectedRoute>} />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <FloatingMenuWrapper user={user} />
    </>
  </Suspense>
);

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setUser(user);
      } catch (error) {
        console.error("Error al verificar la autenticación:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await endSession();
      setUser(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  if (loading) return <LineLoader />;

  return (
    <Router>
      <SubscriptionProvider>
        <AppRoutes user={user} handleLogout={handleLogout} />
      </SubscriptionProvider>
    </Router>
  );
};

export default App;